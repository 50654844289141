export default {
  backLinkDesigners: 'Tutti i designers',
  backLinkFinishes: 'Tutte le finiture',
  searchFail: 'La tua ricerca non ha prodotto risultati.',
  preTitleCatalogue: 'Scarica il catalogo in fomrato PDF',
  privacyPolicy: 'Privacy Policy',
  descriptionPrivacyPolicy:
    'Leggi la nostra Privacy Policy per comprendere come raccogliamo, usiamo e proteggiamo i tuoi dati personali su Zava.',
  cookiePolicy: 'Cookie Policy',
  descriptionCookiePolicy:
    'Scopri come Zava utilizza i cookie per migliorare la tua esperienza online. Leggi la nostra Politica sui Cookie per maggiori informazioni.',
  seoFamilyDescriptionPlaceholder: `Collezione Zava { collection }`,
  seoProductDescriptionPlaceholder: `Prodotto Zava { product }`,
  seoFinishesDescriptionPlaceholder: `Finitura Zava { finishing }`,
  seoDesignerDescriptionPlaceholder: `{ designer } designer e progettista Zava`,
  market: {
    it: 'Italia',
    en: 'Global',
    'en-us': 'Usa',
  },
  seoTitleCollection: 'Collezione',
  seoTitleProduct: 'Prodotto',
  seoDescriptionDiscover: 'Scopri',
}
